@font-face {
  font-family: 'nunito-bold';
  src: url('./fonts/Nunito-Bold.woff2') format('woff2'),
    url('./fonts/Nunito-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'nunito';
  src: url('./fonts/Nunito-Regular.woff2') format('woff2'),
    url('./fonts/Nunito-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body,
div {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* height: 100%; */
  /* width: 100%; */
  margin: 0;
  padding: 0;
  line-height: normal;
  background: var(--mainBg);
  font-family: 'nunito', 'Open Sans', Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  word-wrap: break-word;
  letter-spacing: 0.25px;
}

html,
body {
  background: #292f36;
}

h1 {
  font-size: 2.3rem;
  line-height: 1.2;
}

h2 {
  font-size: 2rem;
  line-height: 1.2;
}

.container {
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 30px 30px 50px 30px;
}

.containerBackground {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 30px 0px 50px 0px;
}

.phoneMockup {
  width: 100%;
}

h2, h3, h4 {
  font-family: 'nunito-bold';
  text-align: center;
  color: #fff;
}

h3 {
  font-size: 2rem;
  line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.2;
}

.containerBackground p {
  font-family: 'nunito';
  color: #fff;
  font-weight: normal;
}

.secondaryButton:hover .iconPadding, .secondaryButton:active .iconPadding {
  color: #292F36;
}

.secondaryButton a {
  text-decoration-color: #c7ef00;
}

.secondaryButton a:visited {
  text-decoration-color: #c7ef00;
}

.iconPadding {
  color: #c7ef00;
  padding-bottom: 1px;
  padding-right: 4px;
}

.tagline {
  align-items: center;
  height: auto;
  margin-top: 40px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
  animation-duration: 2000ms;
  animation-name: fadeIn;
}

.buttonContainer {
  height: auto;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;
  animation-duration: 2000ms;
  animation-name: fadeIn;
  cursor: pointer;
}

.primaryButton {
  flex-direction: row;
  font-family: 'nunito-bold', Arial;
  min-width: 100%;
  border-radius: 50px;
  border: 1px solid #c7ef00;
  background-color: #c7ef00;
  color: #292F36;
  text-decoration: underline;
  padding: 20px 35px;
  transition: all ease 200ms;
}

.primaryButton:hover {
  cursor: pointer;
  box-shadow: 0 0 10px 10px #c7ef0030;
}

.primaryButton:active {
  box-shadow: 0 0 10px 10px #c7ef0030;
}

.secondaryButton {
  flex-direction: row;
  font-family: 'nunito-bold', Arial;
  min-width: 100%;
  border-radius: 50px;
  border: 1px solid #c7ef00;
  background-color: transparent;
  text-decoration: underline;
  text-decoration-color: #c7ef00;
  padding: 20px 35px;
  transition: all ease 200ms;
}

.secondaryButton .buttonInner {
  color: #fff;
  text-align: center;
  text-decoration: #c7ef00;
}

.secondaryButton:hover {
  cursor: pointer;
  background-color: #c7ef00;
}

.secondaryButton:hover .buttonInner {
  color: #292F36;
  text-decoration: underline;
  text-decoration-color: #292F36;
}

.secondaryButton:active {
  background-color: #c7ef00;
}

.secondaryButton:active .buttonInner {
  color: #292F36;
  text-decoration: underline;
  text-decoration-color: #292F36;
}

.primaryButton .buttonInner {
  color: #292F36;
  text-decoration: underline;
  text-decoration-color: #292F36;
}

.primaryButton:hover .buttonInner {
  color: #292F36;
  text-decoration: underline;
  text-decoration-color: #292F36;
}

.primaryButton:active .buttonInner {
  color: #292F36;
  text-decoration: underline;
  text-decoration-color: #292F36;
}

.buttonInner {
  font-family: 'nunito-bold', Arial;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .primaryButton {
    min-width: 270px;
    max-width: 270px;
  }

  .secondaryButton {
    min-width: 270px;
    max-width: 270px;
  }
}

.imgStudent {
  padding-top: 20px;
  pointer-events: none;
}

.imgStudent img {
  width: 100%;
  height: auto;
}

.logo img {
  max-width: 350px;
  width: 100%;
  height: auto;
}

.logo {
  text-align: center;
}

.buttonBack {
  position: absolute;
  width: 50px;
  height: 50px;
  margin-top: 350px;
  left: 30px;
  background-color: rgb(40, 40, 40);
  opacity: 0.8;
  color: #fff;
  border: 0;
  border-radius: 25px;
}

.buttonNext {
  position: absolute;
  width: 50px;
  height: 50px;
  margin-top: 350px;
  right: 30px;
  background-color: rgb(40, 40, 40);
  opacity: 0.8;
  color: #fff;
  border: 0;
  border-radius: 25px;
}

.numberTitle {
  font-family: 'nunito-bold';
  font-size: 2rem;
  margin-bottom: 0;
  color:#c7ef00;
}

.blogPost {
  margin-bottom: 20px;
  flex-direction: column;
  height: 160px;
  width: 260px;
  padding: 30px 30px 0px 30px;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}

.blogPost h4 {
  font-size: 18px;
  text-align: left;
  margin: 0;
  margin-top: -160px;
  position: relative;
}

.blogPost .buttonContainer {
  min-width: min-content;
  margin-top: 0;
  align-items: flex-start;
}

.blogPost .secondaryButton {
  margin-top: 10px;
  min-width: min-content;
}

.blogOpaque {
  height: 160px;
  min-width: 260px;
  background-color: #00000060;
  position: relative;
  top: -30px;
  left: -30px;
  border-radius: 25px;
}

.journalPost {
  flex-direction: column;
  height: 160px;
  width: 260px;
  padding: 30px 30px 0px 30px;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  align-self: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  animation: all 500ms;
}

.journalPost:hover {
  transform: scale(1.05);
}

h5 {
  font-family: 'nunito-bold';
  font-size: 18px;
}

.footerLink {
  font-family: 'nunito';
  color: #e6e6e6;
  text-decoration: underline;
  text-decoration-color: #c7ef00;
}

.appDownload {
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  position: fixed;
  background: #fff;
  bottom: 0;
  z-index: 99;
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 5%);
}

.appContainer h2 {
  font-size: 14px;
  color: #292f36;
  text-align: left;
  justify-content: flex-start;
}

.appIconContainer {
  height: 100%;
  justify-content: center;
  margin: auto 15px auto 0;
}

.appContainer .buttonContainer {
  justify-content: flex-start;
  margin-top: 0;
  color: #666;
  flex-direction: row;
}

.storeIcon {
  flex-direction: row;
  color: #666;
  font-size: 14px;
}

.miniPrimaryButton {
  margin-left: 20px;
  flex-direction: row;
  font-family: 'nunito-bold', Arial;
  min-width: min-content;
  border-radius: 20px;
  border: 1px solid #c7ef00;
  background-color: #c7ef00;
  color: #292F36;
  text-decoration: underline;
  padding: 10px 15px;
  transition: all ease 200ms;
}

.miniPrimaryButton .buttonInner {
  color: #292f36;
}

.miniPrimaryButton:hover {
  cursor: pointer;
  box-shadow: 0 0 10px 10px #c7ef0030;
}

.miniPrimaryButton:active {
  box-shadow: 0 0 10px 10px #c7ef0030;
}

.appLogo {
  width: 56px;
  height: 56px;
}

.imgStudentMd {
  padding: 50px;
  width: 610px;
  height: 700px;
}

.bottomZero {
  padding-bottom: 0;
}

.line-break {
  flex-basis: 100%;
  height: 0;
}

.grid {
  display: flex;
  flex-wrap: wrap; 
}

@media screen and (min-width: 768px) {
  .rowMd {
    flex-direction: row;
  }

  .justifyCenterMd {
    justify-content: center;
  }

  .center {
    width: 100%;
    justify-content: center;
  }

  .maxWidthMd350 {
    max-width: 350px;
    align-self: center;
  }
  
  .maxWidthMd450 {
    max-width: 450px;
    align-self: center;
  }
  
  .container .leftTitle {
    text-align: left;
  }

  .carousel {
    max-width: 300px;
    width: 350px;
  }

  .globeContainer {
    max-width: 600px;
  }

  .column {
    padding-right: 20px;
    padding-top: 20px;
    flex: 1;
  }

  .rowFlex {
    flex-direction: row;
  }

  .paddingTopMd50 {
    padding-top: 50px;
  }

  .alignLeftMd {
    justify-content: flex-start;
  }

  .textCenterMd {
    text-align: center !important;
  }

  .textLeftMd {
    text-align: left !important;
  }

  .dMdNone {
    display: none;
  }

  .blogPost {
    align-self: center;
    margin-right: 20px;
    margin-left: 20px;
  }



  .alignSelfCenterMd {
    align-self: center;
  }

  .maxWidthMd100 {
    max-width: 100px;
  }

  .maxWidthMd800 {
    max-width: 800px;
  }

  .navHome {
    width: 100%;
  }

  .maxWidthMd1000 {
    max-width: 1000px;
    margin: auto;
  }

  .maxWidthMd187 {
    padding-top: 10px;
    max-width: 187px;
  }

  .maxWidthMd760NoMargin {
    max-width: 760px;
  }

  .maxWidthMd600 {
    max-width: 900px;
    /* margin: auto; */
  }

  .minWidthMd300 {
    min-width: 300px;
  }

  .maxWidthMd300 {
    max-width: 300px;
    margin-left: 30px;
  }

  .topMdMinus15 {
    margin-top: -15px;
    padding-top: 0;
  }

  .div404 {
    height: 100%;
    padding: 50px;
  }

  .tagline404 h1 {
    font-size: 2rem;
  }

  .div404 span {
    font-size: 250px;
    line-height: 300px;
    height: 100%;
    opacity: 0.3;
    color:#c7ef00;
  }
}

@media screen and (max-width: 768px) {
  .maxWidthSm300 {
    max-width: 300px;
    justify-content: center;
    width: 300px;
    display: flex;
    margin: 0 auto;
  }

  .div404 span {
    width: 100%;
    text-align: center;
    padding-top: 50px;
    font-size: 100px;
    line-height: 100px;
    height: 100%;
    opacity: 0.3;
    color:#c7ef00;
  }


  .tagline404 {
    margin-top: 0;
  }

  .tagline404 h1 {
    font-size: 1.5rem;
  }
}


.paddingTop0 {
  padding-top: 0;
  padding-bottom: 0;
}

.paddingBottom10 {
  padding-bottom: 15px
}

@media screen and (max-width: 768px) {
  .paddingSm15 {
    padding: 15px;
  }

  .shareCard {
    margin-top: 20px;
  }
}

.lostBanner {
  border-radius: 50px;
  height: 300px;
  width: 100%;
  background-position: center center;
  background-size: contain;
}

.lostBanner .buttonContainer {
  width: 100%;
  margin-top: 240px;
}

.lostBanner .bannerButton {
  border-radius: 25px;
  padding: 10px 15px;
  background-color: #292f36
}

.lostBanner .bannerButton:hover {
  opacity: 0.9;
}

.bannerButton .buttonInner {
  color: #fff;
}


.carousel {
  width: 100%;
}

.alignContentCenter {
  align-content: center;
}

@media screen and (min-width: 768px) {
  .blogTitle {
    padding-left: 0 !important;
  }

  .paddingLeftMd0 {
    padding-left: 0 !important;
  }
}

.blogTitle {
  padding-bottom: 5px !important;
}

.blogTitle h1 {
  padding-bottom: 0px;
  line-height: 1.2;
}

.blogTitle h2 {
  font-family: 'nunito';
  font-size: 24px;
  color: #DDD;
  margin-top: 0;
  margin-bottom: 0px;
  text-align: start;
  line-height: 1.2;
}

.paddingSm15 {
  padding: 15px;
}

@media screen and (min-width: 768px) {
  .paddingSm15 {
    padding: 0;
  }

  .blogCard {
    padding: 40px !important;
    max-width: 600px;
  }


  .containerMd {
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 30px 30px 50px 30px;
  }
}

.blogCard {
  border-radius: 25px;
  background-color: #fff;
  padding: 25px;
}

.blogCard img {
  max-width: 100%;
}

.blogCard h2, .blogCard h3, .blogCard h4, .blogCard h5 {
  text-align: left;
  color:#292f36 !important;
  margin-bottom: 5px;
}

.blogCard h4, .blogCard h5 {
  font-family: 'nunito'
}

.blogCard ul {
  color:#292f36 !important;
  line-height: 1.5;
}

.blogCard p {
  line-height: 1.5;
  font-size: 14px !important;
  text-align: justify;
  color: #292f36;
}

.blogCard a {
  line-height: 1.5;
  font-size: 14px !important;
  text-align: justify;
  color: #657900;
}

.blogCard a:visited {
  line-height: 1.5;
  font-size: 14px !important;
  text-align: justify;
  color: #5b5f47;
}

.blogCard li {
  line-height: 1.5;
  font-size: 14px !important;
  text-align: justify;
  color: #292f36;
}

@media screen and (min-width: 768px) {
  .marginTop300 {
    margin-top: 330px;
  }
  
  .fixed {
    top: 56px;
    position: fixed!important;
    width: 300px;
  }
}

.shareCard {
  background-color: transparent;
  border: 1px solid hsla(0,0%,100%,.2);
  padding: 2em;
  border-radius: 50px;
  color: #fff;
  line-height: 2em;
}

.shareCard h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.shareCard p {
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
}

.justifyContentSpaceEvenly {
  justify-content: space-evenly;
}

.row {
  flex-direction: row;
}

.blogCard table {
  color: #292F36;
}

.blogCard tr:nth-child(even) {
  background-color: #DEDEDE;
}

.blogCard table tr th {
  font-family: 'nunito-bold';
}

.blogCard table thead tr th{
  border-bottom: 1px solid #dedede;
}

.blogCard strong {
  font-family: 'nunito-bold';
}


/**
 * Animations
 */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

::-moz-selection { /* Code for Firefox */
  color: #292f36;
  background: #c7ef00;
}

::selection {
  color: #292f36;
  background: #c7ef00;
}